.search {
	z-index: 114!important;
}
.date-range_arrow {
	flex-shrink: 0;
    position: relative;
    width: 36px;
}

.tabular-numbs{
    font-variant-numeric: tabular-nums;
}

.time-range_wrapper {
    padding: 0.25rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-range_arrow::before {
	border-right: 2px solid #d3dde6;
    border-top: 2px solid #d3dde6;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 12px;
    left: 50%;
    margin-left: -6px;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 12px;
}

.date-range-input {
    width: 50px !important;
    flex: 0.75 1 !important;

    &.day {
        flex: 0.5 1 !important;
    }

    &.month {
        flex: 1 1 !important;
    }
}

.nice-dates-day:before { background-color: #004D40 !important; }
.nice-dates-day:after {
    border: 2px solid #004D40 !important;
}
.nice-dates-popover { 
    max-width: 300px !important; 
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI"!important;
}

.exclamation-icon {
    margin-left: 1rem;
    align-self: center
}