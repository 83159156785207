// 
// user.scss
// Use this to write your custom SCSS
//

/* GLOBAL */
body, html   {
  overflow: hidden;
  font-size: .9rem;
  background-color: $light;
}

li {list-style: none; }

#root {
  height: 100vh;
}

.content {
  height: calc(100vh - 2.625rem - 40px);
}

.card-header {
  background-color: $light;
}

/* FILTERS */
#filter {
  background-color: $white;
}

/* MAP */
.mapboxgl-ctrl-group {
  background: $white!important;
}


/*  ACCORDION & FILTER */
#filter, #timeline {
	.card:first-child {
        border-top: 0px;
	}
} 
