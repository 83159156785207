#filter {
  box-shadow: 2px 4px 6px 0px rgba(0,0,0,0.2);
  height: calc(100% - 0rem + 5px);
  overflow-y: auto;
  z-index: 3;

  &.no-scroll {
    overflow-y:  hidden;
  }
}

#accordion {
   overflow-x: hidden;
}

// FACETS
.facets input {
  margin-top: 3px;
}

.facets span {
  float: right;
}

.facets li {
  padding-right: 0px!important;
}

.facets svg {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.facets svg:hover, .facets svg:focus {
  border-radius: 3px;
  color: #fff;
  background-color: #E53935;
  border: 1px solid #E53935;
}

// VARIABLES TREEVIEW
$super-treeview-text-color: #212529;
$super-treeview-arrow-color: #000;
$super-treeview-tree-stem-color: #000;

.super-treeview * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.super-treeview {
  font-size: 100%;
  margin-left: -1rem;

  > div {
    > .super-treeview-no-children-transition {
      transition-property: all;
    }

    > .super-treeview-no-children-transition-enter {
      opacity: 0;
      height: 0;
      overflow: hidden;

      &.super-treeview-no-children-transition-enter-active {
        opacity: 1;
        height: 0;
      }
    }

    > .super-treeview-no-children {
      overflow: hidden;
      > .super-treeview-no-children-content {
        margin: 2px 0;
      }
    }

    > .super-treeview-node {
      // overflow: hidden;
      > .super-treeview-children-container {
        margin-left: 8px;
        padding-left: 20px;
        border-left: 1px solid $super-treeview-tree-stem-color;

        > .super-treeview-loading {
          padding: 0 3px;
          margin: 2px 0;
        }
      }

      > .super-treeview-node-content {
        padding: 2px 0px;
        margin: 2px 0px;


        > label {
          vertical-align: top;
          text-overflow: ellipsis;
          width: calc(100% - 55px);
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          margin: 0;
          font-weight: normal;
          font-size: 100%;
        }

        > input[type=checkbox] {
          float: left;
          margin: 4px 5px 0 0;
          line-height: normal;
        }

        > .super-treeview-triangle-btn {
          float: left;
          margin-right: 5px;
          cursor: pointer;
        }
        
        > .super-treeview-triangle-btn-none {
          cursor: none;
        }

        > .super-treeview-triangle-btn-right {
          width: 8px;
          height: 10px;
          margin-top: 5px;
          margin-left: 2px;
          border-top: 5px solid transparent;
          border-left: 6px solid $super-treeview-arrow-color;
          border-bottom: 5px solid transparent;
        }

        > .super-treeview-triangle-btn-down {
          width: 10px;
          height: 8px;
          margin-top: 5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid $super-treeview-arrow-color;
        }

        > .delete-btn {
          float: right;
          cursor: pointer;
          border: 1px solid #fff;
          width: 16px;
          height: 16px;
          margin-top: 3px;
        }
        // > .delete-btn:hover, .delete-btn:focus {
        //   border-radius: 3px;
        //   color: #fff;
        //   background-color: #E53935;
        //   border: 1px solid #E53935;
        // }
        > .delete-btn-selected {
          float: right;
          cursor: pointer;
          width: 16px;
          height: 16px;
          margin-top: 3px;
          border-radius: 3px;
          color: #fff;
          background-color: #E53935;
          border: 1px solid #E53935;
        }
        > .delete-btn-selected:hover, .delete-btn-selected:focus, .delete-btn:hover, .delete-btn:focus {
          border-radius: 3px;
          color: #E53935;
          background-color: #fff;
          border: 1px solid #E53935;
        }
      }
    }

    > .super-treeview-node-transition {
      transition-property: all;
    }

    > .super-treeview-node-transition-enter {
      opacity: 0;

      &.super-treeview-node-transition-enter-active {
        opacity: 1;
      }
    }

    > .super-treeview-node-transition-exit {
      opacity: 1;

      &.super-treeview-node-transition-exit-active {
        opacity: 0;
        background-color: red;
      }
    }
  }
}

.switch-container {
  padding-top:0;
  padding-bottom: 0;
}

.switch {
  position: relative;
  right: 0;
}

.and-label {
  padding-right: 5px;
  margin:0;
}

.or-label {
  padding-left: 5px;
  margin:0;
}

.switch-label {
  margin:0 !important;
}

.no-negative-margin {
  margin: 0;
  float: right;
}