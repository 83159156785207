// 
// user-variables.scss
// Use this to overwrite Bootstrap variables
// OTN

//
// Bootstrap overrides ===================================
//


$blue:    #1E88E5;
$indigo:  #3949AB;
$purple:  #5E35B1;
$pink:    #D81B60;
$red:     #E53935;
$orange:  #FB8C00;
$yellow:  #FDD835;
$green:   #43A047;
$teal:    #00897B;
$cyan:    #00ACC1;

$primary:       #004D40;
$secondary:     #FFAB00;
$success:       #00897B;
$info:          #607D8B;
$warning:       #FB8C00;
$danger:        #E53935;
$light:         $gray-100;
$dark:          $gray-800;


.bg-primary, .btn-primary, .badge-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
}
.bg-secondary, .btn-secondary, .badge-secondary {
	background-color: $secondary !important;
	color: $dark !important; 
}
.bg-success {
	background-color: $success !important;
}
.bg-danger {
	background-color: $danger !important;
}
.bg-warning, .btn-warning {
	background-color: $warning !important;
}
.bg-info {
	background-color: $info !important;
}
.bg-light {
	background-color: $light !important;
}
.bg-dark {
	background-color: $dark !important;
}


.btn-primary  {
    border: 1px solid $primary !important;
}
.btn-outline-primary  {
    border: 1px solid $primary !important;
    color: $primary !important;
}
.btn-outline-primary:hover  {
    border: 1px solid $primary !important;
    background-color: $primary !important;
    color: $white !important;
}





