.modal-backdrop
{
    opacity:0.1 !important;
}

.copy-icon {
    margin-left: 0.5rem;
    cursor: pointer;
    color: grey;
    height: 14px;
    width: 14px !important;
}

.copy-icon:hover {
    color: black;
}

.check-icon {
    margin-left: 0.5rem;
    height: 14px;
    width: 14px !important;
}

.copy-spinner {
    margin-left: 0.5rem;
    height: 14px !important;
    width: 14px !important;
}