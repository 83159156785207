.map {
  z-index: 1;
}

.map {
    height: calc(100% - 4rem);
}

.map-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.mapboxgl-canvas:focus { 
  outline: none!important; 
}


.calculation-box {
  background-color: #fff;
  border-radius: 3px;
  bottom: 30px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.10);
  padding: 10px;
  right: 10px;
  z-index: 999999999999999;
  position: absolute;
  max-width: 400px;
}


#map-menu, #layers-menu {
  position: absolute;
  right: 10px;
  top: 307px;
  width: 30px;
  z-index: 5;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  button {
    background: white!important;
    border-radius: 4px;
    padding: 0.35rem 0.5rem;
    width: 30px;
    border: 0px;
  }
  button:hover {
    background: rgb(239, 239, 239)!important;
  }
}

#layers-menu {
 top: 347px;
}

#layers-menu-common {
  position: absolute;
  right: 0px;
  top: auto;
  bottom: 28px;
  z-index: 4;
  background: white!important;
  width: auto;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  div {
    float: left;
  }
}

#mouse-coordinates {
  position: absolute;
  left: 10px;
  top: auto;
  bottom: 80px;
  z-index: 4;
  background: white!important;
  width: auto;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
}


.custom-control-container {
  position: absolute;
  right: 10px;
  top: 235px;
  width: 30px;
  z-index: 4;
  padding-bottom: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  overflow: hidden;
  button {
    background: white!important;
    padding: 0.5rem;
    width: 30px;
    font-size: .7rem;
    text-transform: uppercase;
    border: 0px;
    clear: both;
    background: none;
    font-weight: bold;
  }
  button:hover {
    background: rgb(239, 239, 239)!important;
  }
}

.radio-label {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
}

.mapboxgl-marker {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border:1px solid gray;
    background-color:lightblue;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.mapboxgl-popup-content {
  padding: 20px;
}

#layers-bottom {	
  position: absolute;	
  background: white!important;	
  bottom: 10px;	
  z-index: 100;	
  left: 10px;	
  border-radius: 4px;	
  -webkit-box-shadow: 0 0 2px rgba(0,0,0,.1);	
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);	
  padding-top: 7px!important;	
  padding-bottom: 5px!important;	
  padding-left: 10px;	
  margin-right: 10px;	
  font-size: .7rem;	
  text-transform: uppercase;	
  input {	
    vertical-align: middle;	
    margin-bottom: 3px;	
  }	
  label {	
    padding-left: 5px!important;	
    padding-right: 10px;	
  }	
  div {	
    float: left;	
  }	
}

.super-treeview-triangle-btn-up {
  width: 10px;
  height: 8px;
  margin-top: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #000;
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

.super-treeview-triangle-btn-down {
  width: 10px;
  height: 8px;
  margin-top: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000;
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

.btn-collapse-filter {
  position: absolute;
  right: 10px;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  z-index: 4;
  padding-bottom: 0;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  overflow: hidden;
  button {
    background: white!important;
    padding: 0.5rem;
    width: 30px;
    font-size: .7rem;
    text-transform: uppercase;
    border: 0px;
    clear: both;
    background: none;
    font-weight: bold;
  }
  button:hover {
    background: rgb(239, 239, 239)!important;
  }
  button:focus {
    outline: 0px !important;
  }
}

.btn-expand-filter {
  position: absolute;
  right: 10px;
  top: 30px;
  left: 0px;
  width: 30px;
  height: 30px;
  z-index: 4;
  padding-bottom: 0;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  overflow: hidden;
  button {
    background: white!important;
    padding: 0.5rem;
    width: 30px;
    font-size: .7rem;
    text-transform: uppercase;
    border: 0px;
    clear: both;
    background: none;
    font-weight: bold;
  }
  button:hover {
    background: rgb(239, 239, 239)!important;
  }
  button:focus {
    outline: 0px !important;
  }
}

.btn-collapse-results {
  position: absolute;
  right: 10px;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  z-index: 4;
  padding-bottom: 0;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  button {
    background: white!important;
    padding: 0.5rem;
    width: 30px;
    font-size: .7rem;
    text-transform: uppercase;
    border: 0px;
    clear: both;
    background: none;
    font-weight: bold;
  }
  button:hover {
    background: rgb(239, 239, 239)!important;
  }
  button:focus {
    outline: 0px !important;
  }
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

.mapboxgl-ctrl-top-right {
  top: 50px;
}

.lat-lng {
  float:left;
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.125em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

#map-menu .svg-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

#layers-menu .svg-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-toast {
  position: relative;
  margin-top: 5px;
  max-width: 400px !important;
  flex-basis: 400px !important;
}

.toast-description {
  line-height: 2rem;
  align-self: center;
}

.custom-toast-body {
  display: flex;
  justify-content: space-between;
}

.custom-btn {
  margin-left: 5px;
}