.timeline {
	box-shadow: -2px 4px 6px 0px rgba(0,0,0,0.2);
	z-index: 3;
    background-color: #fff;
}

.observation-list {
    max-height: 100%;
}

.observation-type {
    width: auto;
    height: .75rem;
    margin-bottom: 3px;
    margin-right: 3px;
}

.list-container {
    height: calc(100% - 3rem - 2px);
    overflow-y: auto;

    &.no-scroll {
        overflow-y: none;
    }
}

.btn-collapse {
	margin-top: -5px!important;
    background-color: #fff;
}

.pointer {
    cursor: pointer;
}

.card {
    background-color: transparent;
}

.timeline .list-group li {
    margin-top: -1px!important;      
}

.photoItem img {
    width: 100%;
    height: auto;
    z-index: 100;
}

.photoItem img:hover {
    opacity: 0.9;
    cursor: pointer;
    z-index: 100;
}

.result-item{ 
    border-left: 4px solid #FFF!important;
    transition: .8s;

    .attachment-container {
        width: 3rem;
        height: 3rem;
        background-color: #d5d5d5;
        margin: 0 auto;

        .number {
            color: white;
            font-size: 1.25rem;
            width: 3rem;
            height: 3rem;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
        }

        .img {
            width: 3rem;
            height: 3rem;
        }
    }
}

.result-item:hover {
    cursor: pointer!important;
    background-color: #FDFDFD!important;
    border-left: 4px solid #FFAB00!important;
}

.no-results-container {
    padding: 2rem;
    font-size: 1.2rem;
    color: #6E757C;
    text-align: center;
    .no-results-container-text {
    }
}

.card-full-width {
    margin-left: -1rem;
    margin-right: -1rem;
}

.detail-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__title {
        flex-grow: 1;
    }

    &__value {
        text-align: right;
        white-space: normal;
    }

    &__history {
        text-align: left;
        white-space: normal;
        overflow: auto;
    }
}

.photo-item-container {
    position: relative;
    width: 100%; /* The size you want */
}

.photo-item-container:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}
  
.photo-item-container img {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100%; /* This if for the object-fit */
    height: 100%; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
}

.card-header {
    cursor: pointer;
}